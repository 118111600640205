import { investorAppRoot } from 'constants.ts';

export enum INVESTOR_ROUTES {
    ROOT = investorAppRoot,
    ONBOARDING = 'onboarding',

    INVESTMENTS = 'investments',
    MY_INVESTMENTS = 'all',
    INVESTMENTS_ITEM = ':dealId',

    DEALS = 'deals',
    DEALS_ITEM = ':id',

    ACCOUNT = 'account',
    PROFILE = 'profile',
    TEAM_WORKSPACE = 'team-workspace',
    WALLET = 'wallet',
    PREFERENCES = 'preferences',
    INVESTOR_DOCS = 'investor-docs',
    TAXES = 'taxes',
    // REFERRALS = 'referrals',
}

export const onboardingPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ONBOARDING,
].join('/');
export const dealsPath = [INVESTOR_ROUTES.ROOT, INVESTOR_ROUTES.DEALS].join(
    '/'
);
export const investmentsPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.INVESTMENTS,
].join('/');
export const myInvestmentsPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.INVESTMENTS,
    INVESTOR_ROUTES.MY_INVESTMENTS,
].join('/');
export const accountPath = [INVESTOR_ROUTES.ROOT, INVESTOR_ROUTES.ACCOUNT].join(
    '/'
);
export const profilePath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ACCOUNT,
    INVESTOR_ROUTES.PROFILE,
].join('/');
export const teamWorkspacePath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ACCOUNT,
    INVESTOR_ROUTES.TEAM_WORKSPACE,
].join('/');
export const walletPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ACCOUNT,
    INVESTOR_ROUTES.WALLET,
].join('/');
export const dashboardPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.INVESTMENTS,
].join('/');
export const preferencesPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ACCOUNT,
    INVESTOR_ROUTES.PREFERENCES,
].join('/');
export const investorDocsPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ACCOUNT,
    INVESTOR_ROUTES.INVESTOR_DOCS,
].join('/');
export const taxesPath = [
    INVESTOR_ROUTES.ROOT,
    INVESTOR_ROUTES.ACCOUNT,
    INVESTOR_ROUTES.TAXES,
].join('/');
// export const referralsPath = [
//     INVESTOR_ROUTES.ROOT,
//     INVESTOR_ROUTES.ACCOUNT,
//     INVESTOR_ROUTES.REFERRALS,
// ].join('/');
export const investorBreadcrumbNameMap = {
    [dealsPath]: 'Deals',
    [investmentsPath]: 'Investments',
    [myInvestmentsPath]: 'My Investments',
    [accountPath]: 'Account',
    [profilePath]: 'Profile',
    [teamWorkspacePath]: 'Team workspace',
    [walletPath]: 'Wallet',
    [preferencesPath]: 'Preferences',
    [taxesPath]: 'Taxes',
    [investorDocsPath]: 'Investor Docs',
    // [referralsPath]: 'Referrals',
};
