import { generatePath } from 'react-router-dom';
import { adminAppRoot } from '../../constants.ts';

export enum ADMIN_ROTES {
    ADMIN_ROOT = adminAppRoot,

    DASHMOARD = 'dasboard',
    INVESTORS = 'investors',
    DEALS = 'deals',
    EMPLOYEES = 'employees',
    TAX_DOCUMENTS = 'tax-documents',

    DETAILS = ':pksKeyString',
    NEW = 'new',

    DATA_TABLES = 'data-tables',
    DATA_TABLE_DATA = ':tableName',

    SMB = 'SMB',
    BUSINESS_OWNERS = 'business-owners',
    BUSINESSES = 'businesses',
    DEAL_REQUEST = 'deal-request',
    PENDING_DEAL_NEW = ':pksKeyString/new',
    PENDING_OWNER = 'pending-owners/:pksKeyString',
    PENDING_DEAL = 'pending-deals/:pksKeyString',

    SETTINGS = 'settings',
}

export function path(...paths: ADMIN_ROTES[]) {
    return paths.join('/');
}

export function businessOwnerPath(pksKeyString: string) {
    return generatePath(
        path(
            ADMIN_ROTES.ADMIN_ROOT,
            ADMIN_ROTES.SMB,
            ADMIN_ROTES.BUSINESS_OWNERS,
            ADMIN_ROTES.DETAILS
        ),
        { pksKeyString }
    );
}
export function businessPath(pksKeyString: string) {
    return generatePath(
        path(
            ADMIN_ROTES.ADMIN_ROOT,
            ADMIN_ROTES.SMB,
            ADMIN_ROTES.BUSINESSES,
            ADMIN_ROTES.DETAILS
        ),
        { pksKeyString }
    );
}

export function dealPath(pksKeyString: string) {
    return generatePath(
        path(ADMIN_ROTES.ADMIN_ROOT, ADMIN_ROTES.DEALS, ADMIN_ROTES.DETAILS),
        { pksKeyString }
    );
}

export function pendingOwnerPath(pksKeyString?: string) {
    return generatePath(
        path(
            ADMIN_ROTES.ADMIN_ROOT,
            ADMIN_ROTES.SMB,
            ADMIN_ROTES.DEAL_REQUEST,
            ADMIN_ROTES.PENDING_OWNER
        ),
        { pksKeyString }
    );
}
export function pendingDealNewPath(pksKeyString?: string) {
    return generatePath(
        path(
            ADMIN_ROTES.ADMIN_ROOT,
            ADMIN_ROTES.SMB,
            ADMIN_ROTES.DEAL_REQUEST,
            ADMIN_ROTES.PENDING_DEAL_NEW
        ),
        { pksKeyString }
    );
}

export const pendingDeals = generatePath(
    path(ADMIN_ROTES.ADMIN_ROOT, ADMIN_ROTES.SMB, ADMIN_ROTES.DEAL_REQUEST)
);

export function pendingDealPath(pksKeyString: string) {
    return generatePath(
        path(
            ADMIN_ROTES.ADMIN_ROOT,
            ADMIN_ROTES.SMB,
            ADMIN_ROTES.DEAL_REQUEST,
            ADMIN_ROTES.PENDING_DEAL
        ),
        { pksKeyString }
    );
}

export const adminInvestorsPath = [
    ADMIN_ROTES.ADMIN_ROOT,
    ADMIN_ROTES.INVESTORS,
].join('/');

export const adminSMBPath = [
    ADMIN_ROTES.ADMIN_ROOT,
    ADMIN_ROTES.SMB,
    ADMIN_ROTES.BUSINESS_OWNERS,
].join('/');

export const getTablesPath = () =>
    generatePath([ADMIN_ROTES.ADMIN_ROOT, ADMIN_ROTES.DATA_TABLES].join('/'));
export const getDataModelPath = (tableName: string) =>
    generatePath(
        [
            ADMIN_ROTES.ADMIN_ROOT,
            ADMIN_ROTES.DATA_TABLES,
            ADMIN_ROTES.DATA_TABLE_DATA,
        ].join('/'),
        { tableName }
    );
// export const getDataTableEditPath = (tableName: string, id: string | number) =>
//     generatePath(
//         [
//             ADMIN_ROTES.DATA_TABLES,
//             ADMIN_ROTES.DATA_TABLE_DATA,
//             ADMIN_ROTES.DATA_TABLE_DATA_ITEM,
//         ].join('/'),
//         { tableName, id }
//     );
