import React, { useState, useLayoutEffect, useEffect, MouseEvent } from 'react';
//
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import {
    Backdrop,
    Box,
    Button,
    Card,
    IconButton,
    InputAdornment,
    Link,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import HomegrownSVG from 'Assets/homegrown.svg';
import EntityRafikiSVG from 'Assets/images/EntityRafiki.svg';
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';
import {
    formatSSN,
    minBirthdate,
    removeEmojis,
    validateBirthdate,
    validateEmail,
    validateExpirationDate,
    validateIssueDate,
    validateName,
    validateSSN,
} from 'Helpers/utils.ts';
import { ENTITYENDPOINTS } from 'Services/entity/entityApi.ts';
import { MUIFilesDragAndDrop } from 'Components/MUIDragAndDrop.tsx';
import {
    citizenshipOptions,
    idOptions,
    usStatesOptions,
} from 'Components/onboarding/OnboardingOptions.tsx';
import { INVESTOR_SECONDARY } from '../../app-constants.ts';
import ClientLoader from './components/ClientLoader.tsx';
import { SecondaryInvestorInviteComplete } from './SecondaryInvestorInviteComplete.tsx';
import { NoSecondaryInvestorInvite } from './NoSecondaryInvestorInvite.tsx';
import { BreakpointsProvider } from 'Components/context/BreakpointsContext.tsx';
import { RequiredLabel } from 'Components/RequiredLabel.tsx';

type FormValues = {
    legal_name: string;
    phone_number: string;
    email: string;
    ssn_id: string;
    date_of_birth: string;
    us_citizenship_status: string;
    government_id: {
        id_type: string;
        issuing_state: string;
        id_number: string;
        issue_date: string;
        expiration_date: string;
    };
};

type InvestorData = {
    legal_name: string;
    phone_number: string;
    email: string;
    from_name: string;
};

const listItems = [
    'Net worth: exceeds $1 million, excluding the value of their primary residence',
    'Income: earned over $200,000 per year (or $300,000) with a spouse in each of the last two years',
    'Entities: any entity with assets exceeding $5 million and/or banks, insurance companies, or other investment companies, or a business in which all the equity owners are accredited investors',
];

export const SecondaryInvestorInvite = () => {
    const location = useLocation();
    const code = new URLSearchParams(location.search).get('code');
    const { showSuccess, showError } = useShowToast();

    const [data, setData] = useState<InvestorData | null>(null);
    const [proofDocsFiles, setProofDocsFiles] = useState<File[]>([]);
    const [idFiles, setIdFiles] = useState<File[]>([]);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCodeAvailable, setIsCodeAvailable] = useState(true);
    const [showSSN, setShowSSN] = useState(false);
    const [showDocumentError, setShowDocumentError] = useState(false);

    const handleClickShowSSN = () => setShowSSN((prev) => !prev);
    const handleMouseDownSSN = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: JSON.parse(
            localStorage.getItem('entityInvestorData') || '{}'
        ),
    });

    useEffect(() => {
        const subscription = watch((value) => {
            localStorage.setItem('entityInvestorData', JSON.stringify(value));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.getSecondaryInvestorDetails}`;
                const response = await axios.get(url, {
                    params: { code },
                });
                setIsLoading(false);
                setData(response.data);
            } catch (error: unknown) {
                if (error && typeof error === 'object' && 'response' in error) {
                    const axiosError = error as {
                        response: { data: { status: string } };
                    };
                    if (
                        axiosError.response.data?.status ===
                        'The invite code already used'
                    ) {
                        setIsLoading(false);
                        setIsComplete(true);
                    }
                    if (
                        axiosError.response.data?.status ===
                        'The invite code does not exist'
                    ) {
                        setIsCodeAvailable(false);
                        setIsLoading(false);
                    }
                }
                setIsLoading(false);
            }
        };

        if (code && !isComplete && !data) {
            fetchData();
        }
    }, [code, showError, isComplete, data]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'ssn_id') {
                const ssnValue = value.ssn_id || '';
                const formattedSSN = formatSSN(ssnValue);
                if (ssnValue !== formattedSSN) {
                    setValue('ssn_id', formattedSSN, {
                        shouldValidate: false,
                        shouldDirty: false,
                    });
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setValue]);

    const onSubmit = async (formData: FormValues) => {
        if (idFiles.length === 0 || proofDocsFiles.length === 0) {
            setShowDocumentError(true);
            showError('Please upload all required documents.');
            return;
        }

        const { legal_name, ...rest } = formData;
        const [first_name, last_name] = legal_name.split(' ', 2);

        if (!code) {
            console.error('Code is null or undefined');
            return;
        }

        const submissionData = {
            code: code,
            first_name,
            last_name,
            ...rest,
        };

        setIsSubmitting(true);

        try {
            const saveUrl = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.saveSecondaryInvestor}`;
            await axios.post(saveUrl, submissionData);

            const uploadDocument = async (file: File, documentType: string) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('code', code);
                formData.append('type', documentType);

                const uploadUrl = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.postSecondaryInvestorDocument}`;
                await axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            };

            await Promise.all([
                ...idFiles.map((file) => uploadDocument(file, 'issued_id')),
                ...proofDocsFiles.map((file) => uploadDocument(file, 'proof')),
            ]);

            const completeUrl = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.completeSecondaryInvestorOnboarding}`;
            await axios.post(completeUrl, { code });

            showSuccess(
                'All data and documents have been successfully saved and uploaded.'
            );
            localStorage.removeItem('entityInvestorData');
            setIsComplete(true);
        } catch (error) {
            showError('Failed to submit investor data.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!data && (!isComplete || !isCodeAvailable)) return <ClientLoader />;
    if (isComplete) return <SecondaryInvestorInviteComplete />;
    if (!isCodeAvailable) return <NoSecondaryInvestorInvite />;

    const idType = watch('government_id.id_type');

    return (
        <BreakpointsProvider>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    opacity: 0.7,
                }}
                open={isLoading || !data}
            >
                <ClientLoader />
            </Backdrop>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        padding: '20px 24px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '32px',
                    }}
                >
                    <img
                        src={HomegrownSVG}
                        alt="Homegrown Logo"
                        width={188}
                        height={32}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '32px',
                        flexDirection: 'column',
                        maxWidth: '720px',
                        width: '100%',
                        margin: '0 auto',
                        padding: '0 24px',
                    }}
                >
                    <Typography
                        variant="spectral_32"
                        sx={{ textAlign: 'center' }}
                    >
                        Investor Verification Form
                    </Typography>
                    <img src={EntityRafikiSVG} alt="Entity Rafiki Logo" />
                    <Typography variant="manrope_14">
                        Your business partner, {data?.from_name}, has begun to
                        sign-up for a Homegrown account. In order to complete
                        this process, they need for you to provide information
                        below.
                        <br />
                        Please reach out to{' '}
                        <Link
                            href="mailto:support@joinhomegrown.com"
                            style={{
                                color: 'blue',
                                textDecoration: 'underline',
                            }}
                        >
                            support@joinhomegrown.com
                        </Link>{' '}
                        with any questions.
                    </Typography>
                    <Card
                        variant="outlined"
                        sx={{ borderRadius: 4, width: '100%' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '8px 16px',
                                gap: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}
                            >
                                <SecurityIcon />
                                <Typography variant="manrope_14">
                                    Your provided data is secured
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}
                            >
                                <PersonIcon />
                                <Typography variant="manrope_14">
                                    Your data will only be used with your
                                    permission
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            width: '100%',
                        }}
                    >
                        <Typography variant="manrope_12">
                            Basic information
                        </Typography>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<RequiredLabel label="Legal name" />}
                            variant="filled"
                            size="medium"
                            defaultValue={data?.legal_name || ''}
                            {...register('legal_name', {
                                required: 'Legal name is required',
                                validate: validateName,
                            })}
                            error={!!errors.legal_name}
                            helperText={
                                errors.legal_name
                                    ? errors.legal_name.message
                                    : ''
                            }
                            onInput={(e) => {
                                const input = e.target as HTMLInputElement;
                                input.value = removeEmojis(input.value);
                            }}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                maxLength: 20,
                            }}
                            fullWidth
                            type="tel"
                            placeholder="(000)000-0000"
                            label={<RequiredLabel label="Phone number" />}
                            variant="filled"
                            size="medium"
                            defaultValue={data?.phone_number || ''}
                            {...register('phone_number', {
                                required: 'Phone number is required',
                            })}
                            error={!!errors.phone_number}
                            helperText={errors.phone_number?.message}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<RequiredLabel label="Email" />}
                            variant="filled"
                            size="medium"
                            error={!!errors.email}
                            defaultValue={data?.email || ''}
                            helperText={errors.email?.message}
                            {...register('email', {
                                required: 'Email is required',
                                validate: validateEmail,
                            })}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    maxLength: 11,
                                }}
                                fullWidth
                                label={
                                    <RequiredLabel label="Social Security Number (SSN)" />
                                }
                                variant="filled"
                                size="medium"
                                type={showSSN ? 'text' : 'password'}
                                {...register('ssn_id', {
                                    required: 'SSN is required',
                                    validate: validateSSN,
                                })}
                                error={!!errors.ssn_id}
                                helperText={
                                    errors.ssn_id ? errors.ssn_id.message : ''
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle SSN visibility"
                                                onClick={handleClickShowSSN}
                                                onMouseDown={handleMouseDownSSN}
                                                edge="end"
                                            >
                                                {showSSN ? (
                                                    <VisibilityOffIcon color="primary" />
                                                ) : (
                                                    <VisibilityIcon color="primary" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                                label={
                                    <RequiredLabel label="Citizenship status" />
                                }
                                variant="filled"
                                size="medium"
                                defaultValue=""
                                {...register('us_citizenship_status', {
                                    required: 'Citizenship status is required',
                                })}
                                error={!!errors.us_citizenship_status}
                                helperText={
                                    errors.us_citizenship_status?.message
                                }
                            >
                                {citizenshipOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Typography variant="manrope_12">
                            Government ID
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                                label={<RequiredLabel label="ID type" />}
                                variant="filled"
                                size="medium"
                                defaultValue=""
                                {...register('government_id.id_type', {
                                    required: 'ID type is required',
                                })}
                                error={!!errors.government_id?.id_type}
                                helperText={
                                    errors.government_id?.id_type?.message
                                }
                            >
                                {idOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {idType !== 'Passport' && (
                                <Controller
                                    name="government_id.issuing_state"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Issuing state is required',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            select
                                            label={
                                                <RequiredLabel label="Issuing state" />
                                            }
                                            variant="filled"
                                            size="medium"
                                            error={
                                                !!errors.government_id
                                                    ?.issuing_state
                                            }
                                            helperText={
                                                errors.government_id
                                                    ?.issuing_state?.message
                                            }
                                        >
                                            {usStatesOptions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label={<RequiredLabel label="ID number" />}
                                variant="filled"
                                size="medium"
                                type="text"
                                onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = removeEmojis(input.value);
                                }}
                                {...register('government_id.id_number', {
                                    required: 'ID number is required',
                                })}
                                error={!!errors.government_id?.id_number}
                                helperText={
                                    errors.government_id?.id_number?.message
                                }
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    max: '9999-12-31',
                                    min: minBirthdate,
                                    sx: {
                                        opacity: watch('date_of_birth')
                                            ? 1
                                            : 0.3,
                                    },
                                }}
                                fullWidth
                                label={<RequiredLabel label="Birthdate" />}
                                variant="filled"
                                size="medium"
                                type="date"
                                {...register('date_of_birth', {
                                    required: 'Birthdate is required',
                                    validate: validateBirthdate,
                                })}
                                error={!!errors.date_of_birth}
                                helperText={
                                    errors.date_of_birth
                                        ? errors.date_of_birth.message
                                        : ''
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    max: '9999-12-31',
                                    sx: {
                                        opacity: watch(
                                            'government_id.issue_date'
                                        )
                                            ? 1
                                            : 0.3,
                                    },
                                }}
                                fullWidth
                                label={<RequiredLabel label="Issue date" />}
                                variant="filled"
                                size="medium"
                                type="date"
                                {...register('government_id.issue_date', {
                                    required: 'Issue date is required',
                                    validate: (value) =>
                                        validateIssueDate(
                                            value,
                                            watch('date_of_birth')
                                        ),
                                })}
                                error={!!errors.government_id?.issue_date}
                                helperText={
                                    errors.government_id?.issue_date
                                        ? errors.government_id?.issue_date
                                              ?.message
                                        : ''
                                }
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    max: '9999-12-31',
                                    sx: {
                                        opacity: watch(
                                            'government_id.expiration_date'
                                        )
                                            ? 1
                                            : 0.3,
                                    },
                                }}
                                fullWidth
                                label={
                                    <RequiredLabel label="Expiration date" />
                                }
                                variant="filled"
                                size="medium"
                                type="date"
                                {...register('government_id.expiration_date', {
                                    required: 'Expiration date is required',
                                    validate: validateExpirationDate,
                                })}
                                error={!!errors.government_id?.expiration_date}
                                helperText={
                                    errors.government_id?.expiration_date
                                        ? errors.government_id?.expiration_date
                                              .message
                                        : ''
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Typography variant="manrope_12">
                                Upload Government ID (up to 2)
                            </Typography>
                            <MUIFilesDragAndDrop
                                id="issue-id"
                                files={idFiles}
                                onFilesAdded={(newFiles) =>
                                    setIdFiles((prevFiles) => [
                                        ...prevFiles,
                                        ...newFiles,
                                    ])
                                }
                                onFileDelete={(file) =>
                                    setIdFiles((prevFiles) =>
                                        prevFiles.filter((f) => f !== file)
                                    )
                                }
                                maxFiles={2}
                                disabled={isSubmitting}
                            />
                            {showDocumentError && idFiles.length === 0 && (
                                <Typography variant="manrope_14" color="error">
                                    Please upload at least one document.
                                </Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                }}
                            >
                                <Typography variant="manrope_12">
                                    Upload Accreditation documentation (up to 2)
                                </Typography>
                                <Typography variant="manrope_12">
                                    An accredited investor is someone (or an
                                    entity) that meets one of the following
                                    criteria:
                                </Typography>
                                <ul
                                    style={{
                                        listStyleType: 'disc',
                                        paddingLeft: '20px',
                                    }}
                                >
                                    {listItems.map((item, index) => (
                                        <li
                                            key={index}
                                            style={{ marginBottom: '4px' }}
                                        >
                                            <Typography variant="manrope_12">
                                                {item}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                                <Typography variant="manrope_12">
                                    These qualifications are meant to ensure
                                    that accredited investors can bear the risks
                                    associated with alternative investment
                                    opportunities.
                                </Typography>
                            </Box>
                            <MUIFilesDragAndDrop
                                id="proof-docs"
                                files={proofDocsFiles}
                                onFilesAdded={(newFiles) =>
                                    setProofDocsFiles((prevFiles) => [
                                        ...prevFiles,
                                        ...newFiles,
                                    ])
                                }
                                onFileDelete={(file) =>
                                    setProofDocsFiles((prevFiles) =>
                                        prevFiles.filter((f) => f !== file)
                                    )
                                }
                                maxFiles={2}
                                disabled={isSubmitting}
                            />
                            {showDocumentError &&
                                proofDocsFiles.length === 0 && (
                                    <Typography
                                        variant="manrope_14"
                                        color="error"
                                    >
                                        Please upload at least one document.
                                    </Typography>
                                )}
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="manrope_14" component="div">
                            By pressing Submit, you agree to Homegrown’s{' '}
                            <Link
                                href="https://www.joinhomegrown.com/terms-of-use"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                    color: 'inherit',
                                }}
                            >
                                Terms of Use and Privacy Policy
                            </Link>
                            .
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginBottom: '24px',
                            width: '100%',
                        }}
                    >
                        <Button fullWidth variant="contained" type="submit">
                            Submit
                        </Button>
                    </Box>
                </Box>
            </form>
        </BreakpointsProvider>
    );
};
